.violations-toolbar-bottom-fine-amount-header {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: "AvenirNext Regular", sans-serif;
  font-size: 0.875rem;
  color: #000;
  padding: 0 0 10px 0;
  flex-grow: 0; }

.violations-toolbar-bottom-fine-amount {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: "AvenirNext Regular", sans-serif;
  font-size: 1.75rem;
  color: #ff5252; }
