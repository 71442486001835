.search-form {
  min-height: 75px; }
  .search-form-container {
    border-radius: 4px;
    margin: 0;
    padding: 0;
    border: none;
    display: inline-flex;
    flex-direction: column;
    flex-grow: 1;
    box-shadow: 0px 10px 50px 5px rgba(50, 50, 50, 0.2); }
    @media (min-width: 992px) {
      .search-form-container {
        flex-direction: row; } }
  .search-form-divide {
    border-bottom: solid 1px #e2e4e7;
    display: flex;
    flex-direction: row;
    flex-grow: 1; }
    @media (min-width: 992px) {
      .search-form-divide {
        border-right: solid 1px #e2e4e7;
        border-bottom: none; } }
  .search-form-submit {
    cursor: pointer;
    outline: none;
    margin: 0;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-family: "AvenirNext Medium", sans-serif;
    min-width: 200px;
    min-height: 75px;
    flex-grow: 1;
    background: #5ebefa;
    background: -webkit-linear-gradient(legacy-direction(135deg), #5ebefa, #3089f3);
    background: linear-gradient(135deg, #5ebefa, #3089f3); }
    .search-form-submit:active {
      box-shadow: inset 0 0 40px rgba(0, 0, 0, 0.3); }
    .search-form-submit:focus {
      outline: none; }
    @media (min-width: 992px) {
      .search-form-submit {
        max-width: 200px;
        border-radius: 0 4px 4px 0; } }
    @media (max-width: 991.98px) {
      .search-form-submit {
        border-radius: 0 0 4px 4px; } }
    .search-form-submit:hover {
      background: #5ebefa;
      background: -webkit-linear-gradient(legacy-direction(45deg), #5ebefa, #3089f3);
      background: linear-gradient(45deg, #5ebefa, #3089f3); }
  .search-form-input {
    border-radius: 4px;
    box-sizing: border-box;
    width: 100%;
    height: 75px;
    padding: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #7f7f7f;
    font-size: 16px;
    font-family: "AvenirNext Regular", sans-serif;
    margin: 0px;
    border: none; }
    .search-form-input-capitalize {
      text-transform: uppercase; }
    .search-form-input:focus {
      outline-width: 0; }
