@import 'colors';
$font-gotham-book: 'Gotham-Book', serif;
$font-gotham-light: 'Gotham-Light', serif;
$font-avenir-bold: 'AvenirNext Bold', sans-serif;
$font-avenir-thin: 'AvenirNext Thin', sans-serif;
$font-avenir-regular: 'AvenirNext Regular', sans-serif;
$font-avenir-medium: 'AvenirNext Medium', sans-serif;

$grid-breakpoints: (
  xs: 0px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;

$headings-font-family: $font-gotham-light;
$headings-font-weight: 800;
$modal-backdrop-bg: rgba(0, 0, 0, 0);
$modal-content-bg: #fff;
$modal-content-border-width: 0px;
$modal-content-border-color: #fff;
$modal-dialog-margin: 1.5rem;

$alert-border-radius: 3px;
$alert-border-width: 3px;
$alert-color-level: 6;
$alert-border-level: 2;
$alert-bg-level: -10;
$alert-padding-y: 1.5rem;
$alert-padding-x: 1.5rem;

$search-border-radius: 4px;

$input-padding-y: 1.5rem;
$input-padding-x: 1.5rem;
$input-color: $input-search;
$input-placeholder-color: $checkout-forms-color;
$input-border-color: 1px;
$input-border-width: $separate-search;
$input-box-shadow: 0;
$input-border-radius: $search-border-radius;
