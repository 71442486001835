.violations-selector-table-no-results {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #6c757d;
  padding: 1.5rem; }
  .violations-selector-table-no-results-action {
    cursor: pointer;
    text-decoration: underline;
    font-style: italic;
    font-size: 0.75rem; }
    .violations-selector-table-no-results-action:hover {
      color: #3089f3; }

.violations-selector-table-header {
  font-size: 0.875rem;
  font-family: "AvenirNext Thin", sans-serif;
  color: #3089f3; }

.violations-selector-table-item {
  min-height: 60px;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: center;
  border-bottom: solid 1px #d8d8d8;
  font-size: 0.875rem;
  font-family: "AvenirNext Regular", sans-serif; }
  .violations-selector-table-item-header {
    border: none;
    font-size: 0.75rem;
    font-family: "AvenirNext Regular", sans-serif;
    color: #3089f3; }
  .violations-selector-table-item-selected {
    transition: 0.5s;
    background-color: #ecf4fe; }
  .violations-selector-table-item-payable {
    opacity: 1;
    cursor: pointer; }
  .violations-selector-table-item-not-payable {
    background-color: #f8f9fa;
    opacity: 0.7;
    cursor: default; }
  .violations-selector-table-item-cell {
    padding: 5px 0 5px 0;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: center; }

.violations-selector-table-status-warning {
  color: #ffbe42; }

.violations-selector-table-status-due {
  color: #ff5252; }

.violations-selector-table-status-paid {
  color: #71d77c; }

.violations-selector-table-checkbox {
  flex-shrink: 0;
  flex-grow: 0;
  width: 60px;
  display: flex;
  align-items: center;
  height: 100%; }
