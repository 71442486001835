.button-component {
  cursor: pointer;
  margin: 0;
  border: none;
  display: flex;
  padding: 0 0.5rem 0 0.5rem;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: "AvenirNext Medium", sans-serif;
  min-height: 75px;
  flex-grow: 1;
  border-radius: 4px; }
  .button-component:active {
    box-shadow: inset 0 0 40px rgba(0, 0, 0, 0.3); }
  .button-component:focus {
    outline: none; }
  .button-component:disabled {
    cursor: not-allowed;
    background: #adb5bd;
    background: -webkit-linear-gradient(legacy-direction(135deg), #adb5bd, #6c757d);
    background: linear-gradient(135deg, #adb5bd, #6c757d);
    box-shadow: none; }
    .button-component:disabled:hover {
      background: #adb5bd;
      background: -webkit-linear-gradient(legacy-direction(45deg), #adb5bd, #6c757d);
      background: linear-gradient(45deg, #adb5bd, #6c757d); }
  .button-component-blue {
    background: #5ebefa;
    background: -webkit-linear-gradient(legacy-direction(135deg), #5ebefa, #3089f3);
    background: linear-gradient(135deg, #5ebefa, #3089f3); }
    .button-component-blue:hover {
      background: #5ebefa;
      background: -webkit-linear-gradient(legacy-direction(45deg), #5ebefa, #3089f3);
      background: linear-gradient(45deg, #5ebefa, #3089f3); }
    .button-component-blue-shadow {
      box-shadow: 0 15px 30px rgba(35, 123, 245, 0.4); }
  .button-component-green {
    background: #89e492;
    background: -webkit-linear-gradient(legacy-direction(135deg), #89e492, #34c745);
    background: linear-gradient(135deg, #89e492, #34c745); }
    .button-component-green:hover {
      background: #89e492;
      background: -webkit-linear-gradient(legacy-direction(15deg), #89e492, #34c745);
      background: linear-gradient(15deg, #89e492, #34c745); }
    .button-component-green-shadow {
      box-shadow: 0 15px 30px rgba(98, 211, 109, 0.4); }
