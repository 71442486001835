.checkout-summary-container {
  border-radius: 4px;
  background-color: white;
  box-shadow: 2px 2px 5px 3px rgba(50, 50, 50, 0.2);
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  margin: 0 2.5rem 2.5rem 2.5rem;
  font-size: 0.875rem;
  font-family: "AvenirNext Regular", sans-serif; }
  @media (max-width: 991.98px) {
    .checkout-summary-container {
      margin: 0 1rem 1rem 1rem; } }

.checkout-summary-secured {
  padding: 1.3rem 0 0rem 0; }

.checkout-summary-list {
  margin: 1rem 0 0 0;
  display: flex;
  flex-direction: column; }

.checkout-summary-list-action {
  color: #3089f3;
  text-align: center;
  padding: 5px;
  cursor: pointer; }
  .checkout-summary-list-action:hover {
    text-decoration: underline; }

.checkout-summary-violations-item {
  color: #acb3b7; }

.checkout-summary-list-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 0 5px 0; }

.checkout-summary-totals-list {
  padding: 0 0 0.8rem 0;
  margin: 0.8rem 0 0 0;
  border-bottom: solid 1px #e2e4e7; }

.checkout-summary-item-amount {
  flex-shrink: 0;
  width: 90px; }
  .checkout-summary-item-amount b {
    font-family: "AvenirNext Medium", sans-serif;
    font-style: italic;
    opacity: 0.8; }

.checkout-summary-grand-total {
  font-size: 1rem;
  color: #ff5252;
  font-family: "AvenirNext Medium", sans-serif; }
