.checkbox-default {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: solid 1px #e2e4e7;
  padding: 4px;
  cursor: pointer;
  margin: 3px;
  border-radius: 1px;
  transition: 0.5s; }
  .checkbox-default-disabled {
    opacity: 0.5;
    background-color: #e2e4e7;
    cursor: default; }
