.error-alert-container {
  position: fixed;
  max-width: 500px;
  margin: 40px;
  bottom: 0px;
  right: 0px;
  z-index: 99999999; }
  .error-alert-container div {
    border: 0;
    margin: 0; }
    .error-alert-container div p {
      margin: 0; }
