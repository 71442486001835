.checkout-dropin-wrapper {
  margin: 0px auto 0px auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  font-size: 0.875rem;
  font-family: "AvenirNext Regular", sans-serif; }

.cko-dropin-field-spacer {
  min-height: 15px; }

.checkout-dropin-fade {
  width: 100%; }
