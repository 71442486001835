.violations-toolbar-top-seperator {
  margin: 0 0 0 0.9rem;
  padding: 0 0 0 0.9em;
  border-left: solid 1px #d8d8d8; }

.violations-toolbar-top-action {
  color: #6c757d;
  font-size: 0.875rem;
  cursor: pointer; }
  .violations-toolbar-top-action:hover {
    color: #3089f3; }

.violations-toolbar-top-check-action {
  margin-left: 10px; }

.violations-toolbar-top-border-bottom {
  border-bottom: solid 1px #d8d8d8; }
