// New colors
$main-blue: rgb(48, 137, 243);
$main-orange: #ffbe42;
$footer-text: #aeb4b9;

$red: #ff5252;
$orange: #ffbe42;
$green: #71d77c;
$cyan: #17a2b8 !default;

$white: #fff !default;
$gray-text: #7d7d7d;
$gray-line: #eff0f3;
$list-seperator-line: #d8d8d8;

$input-search: #7f7f7f;
$separate-search: #e2e4e7;
$input-checkout: #40505a;

$checkout-forms-color: #acb3b7;
$checkout-forms-placeholder-color: #acb3b7;

$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

// Array of colors from _variables.scss
$theme-colors: (
  primary: $main-blue,
  secondary: $gray-600,
  success: $green,
  info: $cyan,
  warning: $orange,
  danger: $red,
  light: $gray-100,
  dark: $gray-800
) !default;
