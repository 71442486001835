.required-notice {
  color: #7d7d7d;
  padding: 20px;
  background-color: #eff0f3;
  border-radius: 4px;
  font-family: "AvenirNext Medium", sans-serif; }

.select-img-group {
  padding: 10px 10px;
  margin-top: 10px;
  background-color: #fff;
  border-radius: 4px; }

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1; }

.inputfile + label {
  font-size: 1em;
  font-weight: 700;
  cursor: pointer;
  margin: 0;
  border: none;
  display: flex;
  color: #fff;
  padding: 0.5rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  font-family: "AvenirNext Medium", sans-serif;
  height: 100%;
  flex-grow: 1;
  border-radius: 4px;
  background: #5ebefa;
  background: -webkit-linear-gradient(legacy-direction(135deg), #5ebefa, #3089f3);
  background: linear-gradient(135deg, #5ebefa, #3089f3); }

.inputfile:focus + label,
.inputfile + label:hover {
  background-color: red;
  background: #5ebefa;
  background: -webkit-linear-gradient(legacy-direction(45deg), #5ebefa, #3089f3);
  background: linear-gradient(45deg, #5ebefa, #3089f3); }

.inputfile:focus + label {
  outline: none; }

.inputfile-previewText {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #acb3b7;
  font-style: italic;
  font-family: "AvenirNext Thin", sans-serif; }

.inputfile-imgPreview {
  display: flex;
  flex-shrink: 0;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 10px 0 0;
  height: 100px;
  width: 100px;
  background-color: #fff;
  border-radius: 4px; }
  .inputfile-imgPreview .actual-img {
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    height: 100%; }
