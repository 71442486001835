.search-box-default {
  height: 60px;
  position: relative;
  flex-direction: row;
  display: flex;
  flex-grow: 1;
  width: 100%; }
  .search-box-default input {
    -webkit-appearance: none;
    box-shadow: 0;
    border-radius: 4px;
    border: solid 1px #e2e4e7;
    background-color: white;
    text-indent: 50px;
    width: 100%;
    font-size: 16px;
    color: #7f7f7f;
    font-family: "AvenirNext Regular", sans-serif;
    height: 100%; }
    .search-box-default input:focus {
      border: solid 1px #adb5bd; }
    .search-box-default input:focus {
      outline-width: 0; }
  .search-box-default div {
    cursor: text;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 100%; }
    .search-box-default div img {
      height: 20px;
      width: 20px; }
