@import '../style/_variables.scss';
@import '../style/_colors.scss';

.clickfeed {
    padding: 12px 24px;
    color:#9aa1a5;
    border-radius: $search-border-radius;
    margin: 0px 24px 0px 24px;
    box-shadow: 2px 2px 5px 3px rgba(50, 50, 50, 0.2);
}
.clickfeed h3 {
    color: #007bff;
    font-size: 24px;
    margin: 10px 0;
    font-family: AvenirNext bold;
}
.clickfeed button {
    background: linear-gradient(0deg, #1C6DDF, #6ca0ee);
    border-radius: 10px;
    border: 2px solid #448AEC;
    color: #fff;
    width: 200px;
    box-shadow: 0 10px 10px -2px rgba(0,0,0,.25);
    height: 50px;
    margin-bottom: 12px;
    font-size: 16px;
    font-family: AvenirNext bold;
    &:focus {
        outline: none;
    }
}
.clickfeed p {
    font-size: 12px;
    color: $gray-900;
}
.clickfeed img {
    max-height: 55px;
}
.clickfeed #cf_result button {
    display: none;
}
.clickfeed .cf_flex {
    display: flex;
    align-items: center;
    min-height: 55px;
}
.clickfeed .sponsor {
    padding-right: 20px;
    color: $gray-900;
}
.spinner {
    margin: 5px 0px 0px 0px;
}
@media only screen and (max-width: 991px) {
    .clickfeed {
        margin: 0px 0px 25px 0px;
    }
    .clickfeed p {
        font-size: 15px;
    }
}
