.what-we-offer-img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 130px;
  width: 200px;
  margin: 0 0 1rem 0; }

.what-we-offer-description {
  text-align: center;
  font-family: "AvenirNext Regular", sans-serif;
  color: #000;
  font-size: 0.8125rem;
  max-width: 180px; }
