.featured-in-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%; }
  @media (min-width: 992px) {
    .featured-in-main {
      flex-direction: row;
      margin: 0; } }

.featured-in-site:link {
  margin: 2rem 0 2rem 0;
  opacity: 1;
  text-decoration: none;
  transition: 0.2s; }

.featured-in-site:hover {
  transition: 0.2s;
  filter: drop-shadow(4px 4px 4px #c0c0c0);
  opacity: 0.75; }

.featured-in-img {
  max-height: 30px;
  max-width: 200px; }
