.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(48,137,243, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E"); }

.mobile-menu {
  margin-top: 1rem;
  padding-left: 1rem; }
  .mobile-menu .nav-link-round-button {
    margin: 0; }

.logo-hover {
  opacity: 0.7;
  transition: 0.2s; }
  .logo-hover:hover {
    opacity: 1;
    transition: 0.2s; }

.header-logo-text {
  color: #3089f3;
  font-size: 1.125rem; }
  .header-logo-text-pay-chicago {
    font-family: "AvenirNext Bold", sans-serif;
    letter-spacing: 2px; }
  .header-logo-text-tickets {
    letter-spacing: 2px;
    font-family: "AvenirNext Thin", sans-serif;
    padding-left: 8px;
    font-weight: 500;
    color: #ffbe42; }
  .header-logo-text-powered-by {
    font-family: "Gotham-Book", serif;
    font-size: 0.625rem;
    color: #6c757d; }
  .header-logo-text-winit-logo {
    margin-left: 10px;
    border: solid 1px #e9ecef;
    padding: 0px 10px 5px 10px;
    border-radius: 10px; }

.navbar-border-bottom {
  border-bottom: solid 1px #eff0f3; }

.nav-link {
  cursor: pointer;
  color: #000;
  font-family: "Gotham-Book", serif;
  font-size: 0.75rem; }
  .nav-link:hover {
    color: #3089f3; }
  .nav-link-round-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    margin-left: 1rem;
    color: #fff;
    border-radius: 30px;
    background: #5ebefa;
    background: -webkit-linear-gradient(legacy-direction(90deg), #5ebefa, #3089f3);
    background: linear-gradient(90deg, #5ebefa, #3089f3); }
    .nav-link-round-button:hover {
      color: #fff;
      background: #5ebefa;
      background: -webkit-linear-gradient(legacy-direction(180deg), #5ebefa, #3089f3);
      background: linear-gradient(180deg, #5ebefa, #3089f3); }
