.footer-dark {
  font-family: "AvenirNext Thin", sans-serif;
  font-size: 0.75rem;
  color: #aeb4b9;
  background: #58646e;
  background: -webkit-linear-gradient(legacy-direction(45deg), #58646e, #404c57);
  background: linear-gradient(45deg, #58646e, #404c57); }

.footer-social-icon {
  opacity: 1;
  transition: 0.2s; }
  .footer-social-icon:hover {
    opacity: 0.6;
    transition: 0.2s; }

.footer-link {
  margin: 0 1rem 0 1rem;
  cursor: pointer;
  transition: 0.5s;
  color: #aeb4b9; }
  .footer-link:hover {
    text-decoration: underline;
    color: #fff;
    transition: 0.5s; }

.affiliate {
  font-size: 1.9em;
  font-weight: bold; }
