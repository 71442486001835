.section-title {
  font-family: "AvenirNext Regular", sans-serif;
  letter-spacing: 2px;
  font-size: 0.8125rem;
  margin-bottom: 1rem;
  font-weight: 800; }
  .section-title-active {
    color: #3089f3; }
  .section-title-disabled {
    color: #7d7d7d; }
  .section-title-clickable {
    cursor: pointer; }
